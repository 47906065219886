.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template:
    "menu menu" min-content
    "xml canvas" 1fr
    "css canvas" 1fr / minmax(30rem, 45rem) auto;
  flex-flow: column nowrap;
  justify-content: stretch;
}

.App .dropdown-menu {
  min-width: 15rem;
}
