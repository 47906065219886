.Flag {
  display: inline-block;
  width: 22px;
  height: 15px;
  background: url(./Flag.png) no-repeat;
  background-size: 100%;
  vertical-align: -2px;
}

.Flag.ad {
  background-position: 0 0.413223%;
}

.Flag.ae {
  background-position: 0 0.826446%;
}

.Flag.af {
  background-position: 0 1.239669%;
}

.Flag.ag {
  background-position: 0 1.652893%;
}

.Flag.ai {
  background-position: 0 2.066116%;
}

.Flag.al {
  background-position: 0 2.479339%;
}

.Flag.am {
  background-position: 0 2.892562%;
}

.Flag.an {
  background-position: 0 3.305785%;
}

.Flag.ao {
  background-position: 0 3.719008%;
}

.Flag.aq {
  background-position: 0 4.132231%;
}

.Flag.ar {
  background-position: 0 4.545455%;
}

.Flag.as {
  background-position: 0 4.958678%;
}

.Flag.at {
  background-position: 0 5.371901%;
}

.Flag.au {
  background-position: 0 5.785124%;
}

.Flag.aw {
  background-position: 0 6.198347%;
}

.Flag.az {
  background-position: 0 6.61157%;
}

.Flag.ba {
  background-position: 0 7.024793%;
}

.Flag.bb {
  background-position: 0 7.438017%;
}

.Flag.bd {
  background-position: 0 7.85124%;
}

.Flag.be {
  background-position: 0 8.264463%;
}

.Flag.bf {
  background-position: 0 8.677686%;
}

.Flag.bg {
  background-position: 0 9.090909%;
}

.Flag.bh {
  background-position: 0 9.504132%;
}

.Flag.bi {
  background-position: 0 9.917355%;
}

.Flag.bj {
  background-position: 0 10.330579%;
}

.Flag.bm {
  background-position: 0 10.743802%;
}

.Flag.bn {
  background-position: 0 11.157025%;
}

.Flag.bo {
  background-position: 0 11.570248%;
}

.Flag.br {
  background-position: 0 11.983471%;
}

.Flag.bs {
  background-position: 0 12.396694%;
}

.Flag.bt {
  background-position: 0 12.809917%;
}

.Flag.bv {
  background-position: 0 13.22314%;
}

.Flag.bw {
  background-position: 0 13.636364%;
}

.Flag.by {
  background-position: 0 14.049587%;
}

.Flag.bz {
  background-position: 0 14.46281%;
}

.Flag.ca {
  background-position: 0 14.876033%;
}

.Flag.cc {
  background-position: 0 15.289256%;
}

.Flag.cd {
  background-position: 0 15.702479%;
}

.Flag.cf {
  background-position: 0 16.115702%;
}

.Flag.cg {
  background-position: 0 16.528926%;
}

.Flag.ch {
  background-position: 0 16.942149%;
}

.Flag.ci {
  background-position: 0 17.355372%;
}

.Flag.ck {
  background-position: 0 17.768595%;
}

.Flag.cl {
  background-position: 0 18.181818%;
}

.Flag.cm {
  background-position: 0 18.595041%;
}

.Flag.cn {
  background-position: 0 19.008264%;
}

.Flag.co {
  background-position: 0 19.421488%;
}

.Flag.cr {
  background-position: 0 19.834711%;
}

.Flag.cu {
  background-position: 0 20.247934%;
}

.Flag.cv {
  background-position: 0 20.661157%;
}

.Flag.cx {
  background-position: 0 21.07438%;
}

.Flag.cy {
  background-position: 0 21.487603%;
}

.Flag.cz {
  background-position: 0 21.900826%;
}

.Flag.de {
  background-position: 0 22.31405%;
}

.Flag.dj {
  background-position: 0 22.727273%;
}

.Flag.dk {
  background-position: 0 23.140496%;
}

.Flag.dm {
  background-position: 0 23.553719%;
}

.Flag.do {
  background-position: 0 23.966942%;
}

.Flag.dz {
  background-position: 0 24.380165%;
}

.Flag.ec {
  background-position: 0 24.793388%;
}

.Flag.ee {
  background-position: 0 25.206612%;
}

.Flag.eg {
  background-position: 0 25.619835%;
}

.Flag.eh {
  background-position: 0 26.033058%;
}

.Flag.er {
  background-position: 0 26.446281%;
}

.Flag.es {
  background-position: 0 26.859504%;
}

.Flag.et {
  background-position: 0 27.272727%;
}

.Flag.fi {
  background-position: 0 27.68595%;
}

.Flag.fj {
  background-position: 0 28.099174%;
}

.Flag.fk {
  background-position: 0 28.512397%;
}

.Flag.fm {
  background-position: 0 28.92562%;
}

.Flag.fo {
  background-position: 0 29.338843%;
}

.Flag.fr {
  background-position: 0 29.752066%;
}

.Flag.ga {
  background-position: 0 30.165289%;
}

.Flag.gd {
  background-position: 0 30.578512%;
}

.Flag.ge {
  background-position: 0 30.991736%;
}

.Flag.gf {
  background-position: 0 31.404959%;
}

.Flag.gh {
  background-position: 0 31.818182%;
}

.Flag.gi {
  background-position: 0 32.231405%;
}

.Flag.gl {
  background-position: 0 32.644628%;
}

.Flag.gm {
  background-position: 0 33.057851%;
}

.Flag.gn {
  background-position: 0 33.471074%;
}

.Flag.gp {
  background-position: 0 33.884298%;
}

.Flag.gq {
  background-position: 0 34.297521%;
}

.Flag.gr {
  background-position: 0 34.710744%;
}

.Flag.gs {
  background-position: 0 35.123967%;
}

.Flag.gt {
  background-position: 0 35.53719%;
}

.Flag.gu {
  background-position: 0 35.950413%;
}

.Flag.gw {
  background-position: 0 36.363636%;
}

.Flag.gy {
  background-position: 0 36.77686%;
}

.Flag.hk {
  background-position: 0 37.190083%;
}

.Flag.hm {
  background-position: 0 37.603306%;
}

.Flag.hn {
  background-position: 0 38.016529%;
}

.Flag.hr {
  background-position: 0 38.429752%;
}

.Flag.ht {
  background-position: 0 38.842975%;
}

.Flag.hu {
  background-position: 0 39.256198%;
}

.Flag.id {
  background-position: 0 39.669421%;
}

.Flag.ie {
  background-position: 0 40.082645%;
}

.Flag.il {
  background-position: 0 40.495868%;
}

.Flag.in {
  background-position: 0 40.909091%;
}

.Flag.io {
  background-position: 0 41.322314%;
}

.Flag.iq {
  background-position: 0 41.735537%;
}

.Flag.ir {
  background-position: 0 42.14876%;
}

.Flag.is {
  background-position: 0 42.561983%;
}

.Flag.it {
  background-position: 0 42.975207%;
}

.Flag.jm {
  background-position: 0 43.38843%;
}

.Flag.jo {
  background-position: 0 43.801653%;
}

.Flag.jp {
  background-position: 0 44.214876%;
}

.Flag.ke {
  background-position: 0 44.628099%;
}

.Flag.kg {
  background-position: 0 45.041322%;
}

.Flag.kh {
  background-position: 0 45.454545%;
}

.Flag.ki {
  background-position: 0 45.867769%;
}

.Flag.km {
  background-position: 0 46.280992%;
}

.Flag.kn {
  background-position: 0 46.694215%;
}

.Flag.kp {
  background-position: 0 47.107438%;
}

.Flag.kr {
  background-position: 0 47.520661%;
}

.Flag.kw {
  background-position: 0 47.933884%;
}

.Flag.ky {
  background-position: 0 48.347107%;
}

.Flag.kz {
  background-position: 0 48.760331%;
}

.Flag.la {
  background-position: 0 49.173554%;
}

.Flag.lb {
  background-position: 0 49.586777%;
}

.Flag.lc {
  background-position: 0 50%;
}

.Flag.li {
  background-position: 0 50.413223%;
}

.Flag.lk {
  background-position: 0 50.826446%;
}

.Flag.lr {
  background-position: 0 51.239669%;
}

.Flag.ls {
  background-position: 0 51.652893%;
}

.Flag.lt {
  background-position: 0 52.066116%;
}

.Flag.lu {
  background-position: 0 52.479339%;
}

.Flag.lv {
  background-position: 0 52.892562%;
}

.Flag.ly {
  background-position: 0 53.305785%;
}

.Flag.ma {
  background-position: 0 53.719008%;
}

.Flag.mc {
  background-position: 0 54.132231%;
}

.Flag.md {
  background-position: 0 54.545455%;
}

.Flag.me {
  background-position: 0 54.958678%;
}

.Flag.mg {
  background-position: 0 55.371901%;
}

.Flag.mh {
  background-position: 0 55.785124%;
}

.Flag.mk {
  background-position: 0 56.198347%;
}

.Flag.ml {
  background-position: 0 56.61157%;
}

.Flag.mm {
  background-position: 0 57.024793%;
}

.Flag.mn {
  background-position: 0 57.438017%;
}

.Flag.mo {
  background-position: 0 57.85124%;
}

.Flag.mp {
  background-position: 0 58.264463%;
}

.Flag.mq {
  background-position: 0 58.677686%;
}

.Flag.mr {
  background-position: 0 59.090909%;
}

.Flag.ms {
  background-position: 0 59.504132%;
}

.Flag.mt {
  background-position: 0 59.917355%;
}

.Flag.mu {
  background-position: 0 60.330579%;
}

.Flag.mv {
  background-position: 0 60.743802%;
}

.Flag.mw {
  background-position: 0 61.157025%;
}

.Flag.mx {
  background-position: 0 61.570248%;
}

.Flag.my {
  background-position: 0 61.983471%;
}

.Flag.mz {
  background-position: 0 62.396694%;
}

.Flag.na {
  background-position: 0 62.809917%;
}

.Flag.nc {
  background-position: 0 63.22314%;
}

.Flag.ne {
  background-position: 0 63.636364%;
}

.Flag.nf {
  background-position: 0 64.049587%;
}

.Flag.ng {
  background-position: 0 64.46281%;
}

.Flag.ni {
  background-position: 0 64.876033%;
}

.Flag.nl {
  background-position: 0 65.289256%;
}

.Flag.no {
  background-position: 0 65.702479%;
}

.Flag.np {
  background-position: 0 66.115702%;
}

.Flag.nr {
  background-position: 0 66.528926%;
}

.Flag.nu {
  background-position: 0 66.942149%;
}

.Flag.nz {
  background-position: 0 67.355372%;
}

.Flag.om {
  background-position: 0 67.768595%;
}

.Flag.pa {
  background-position: 0 68.181818%;
}

.Flag.pe {
  background-position: 0 68.595041%;
}

.Flag.pf {
  background-position: 0 69.008264%;
}

.Flag.pg {
  background-position: 0 69.421488%;
}

.Flag.ph {
  background-position: 0 69.834711%;
}

.Flag.pk {
  background-position: 0 70.247934%;
}

.Flag.pl {
  background-position: 0 70.661157%;
}

.Flag.pm {
  background-position: 0 71.07438%;
}

.Flag.pn {
  background-position: 0 71.487603%;
}

.Flag.pr {
  background-position: 0 71.900826%;
}

.Flag.pt {
  background-position: 0 72.31405%;
}

.Flag.pw {
  background-position: 0 72.727273%;
}

.Flag.py {
  background-position: 0 73.140496%;
}

.Flag.qa {
  background-position: 0 73.553719%;
}

.Flag.re {
  background-position: 0 73.966942%;
}

.Flag.ro {
  background-position: 0 74.380165%;
}

.Flag.rs {
  background-position: 0 74.793388%;
}

.Flag.ru {
  background-position: 0 75.206612%;
}

.Flag.rw {
  background-position: 0 75.619835%;
}

.Flag.sa {
  background-position: 0 76.033058%;
}

.Flag.sb {
  background-position: 0 76.446281%;
}

.Flag.sc {
  background-position: 0 76.859504%;
}

.Flag.sd {
  background-position: 0 77.272727%;
}

.Flag.se {
  background-position: 0 77.68595%;
}

.Flag.sg {
  background-position: 0 78.099174%;
}

.Flag.sh {
  background-position: 0 78.512397%;
}

.Flag.si {
  background-position: 0 78.92562%;
}

.Flag.sj {
  background-position: 0 79.338843%;
}

.Flag.sk {
  background-position: 0 79.752066%;
}

.Flag.sl {
  background-position: 0 80.165289%;
}

.Flag.sm {
  background-position: 0 80.578512%;
}

.Flag.sn {
  background-position: 0 80.991736%;
}

.Flag.so {
  background-position: 0 81.404959%;
}

.Flag.sr {
  background-position: 0 81.818182%;
}

.Flag.ss {
  background-position: 0 82.231405%;
}

.Flag.st {
  background-position: 0 82.644628%;
}

.Flag.sv {
  background-position: 0 83.057851%;
}

.Flag.sy {
  background-position: 0 83.471074%;
}

.Flag.sz {
  background-position: 0 83.884298%;
}

.Flag.tc {
  background-position: 0 84.297521%;
}

.Flag.td {
  background-position: 0 84.710744%;
}

.Flag.tf {
  background-position: 0 85.123967%;
}

.Flag.tg {
  background-position: 0 85.53719%;
}

.Flag.th {
  background-position: 0 85.950413%;
}

.Flag.tj {
  background-position: 0 86.363636%;
}

.Flag.tk {
  background-position: 0 86.77686%;
}

.Flag.tl {
  background-position: 0 87.190083%;
}

.Flag.tm {
  background-position: 0 87.603306%;
}

.Flag.tn {
  background-position: 0 88.016529%;
}

.Flag.to {
  background-position: 0 88.429752%;
}

.Flag.tp {
  background-position: 0 88.842975%;
}

.Flag.tr {
  background-position: 0 89.256198%;
}

.Flag.tt {
  background-position: 0 89.669421%;
}

.Flag.tv {
  background-position: 0 90.082645%;
}

.Flag.tw {
  background-position: 0 90.495868%;
}

.Flag.ty {
  background-position: 0 90.909091%;
}

.Flag.tz {
  background-position: 0 91.322314%;
}

.Flag.ua {
  background-position: 0 91.735537%;
}

.Flag.ug {
  background-position: 0 92.14876%;
}

.Flag.gb,
.Flag.uk {
  background-position: 0 92.561983%;
}

.Flag.um {
  background-position: 0 92.975207%;
}

.Flag.us {
  background-position: 0 93.38843%;
}

.Flag.uy {
  background-position: 0 93.801653%;
}

.Flag.uz {
  background-position: 0 94.214876%;
}

.Flag.va {
  background-position: 0 94.628099%;
}

.Flag.vc {
  background-position: 0 95.041322%;
}

.Flag.ve {
  background-position: 0 95.454545%;
}

.Flag.vg {
  background-position: 0 95.867769%;
}

.Flag.vi {
  background-position: 0 96.280992%;
}

.Flag.vn {
  background-position: 0 96.694215%;
}

.Flag.vu {
  background-position: 0 97.107438%;
}

.Flag.wf {
  background-position: 0 97.520661%;
}

.Flag.ws {
  background-position: 0 97.933884%;
}

.Flag.ye {
  background-position: 0 98.347107%;
}

.Flag.za {
  background-position: 0 98.760331%;
}

.Flag.zm {
  background-position: 0 99.173554%;
}

.Flag.zr {
  background-position: 0 99.586777%;
}

.Flag.zw {
  background-position: 0 100%;
}
