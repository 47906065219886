.CodeMirror {
  position: absolute;
  height: 100%;
  width: 100%;
}

.cm-s-nord span.cm-tag {
  color: var(--bs-yellow);
}

.cm-s-nord span.cm-attribute,
.cm-s-nord span.cm-property {
  color: #9accbb;
}

.CSSEditor .cm-s-nord.CodeMirror,
.CSSEditor .cm-s-nord .CodeMirror-gutters {
  background: #373f4d;
}
